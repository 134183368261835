import { GetStaticProps, NextPage } from 'next'
import { useScreenView } from '@style-space/components/src/lib/mixpanel/useScreenView'

import { NotionHq, NOTION_DATABASE_ID } from '../utils/utils'
import Home from '../components/Home'

type Props = {
  data: any
}

const HomePage: NextPage<Props> = ({ data }) => {
  useScreenView('Blog')
  return <Home data={data} />
}
HomePage.displayName = 'Home'

export const getServerSideProps: GetStaticProps = async () => {
  if (!NOTION_DATABASE_ID) {
    throw new Error('NOTION_DATABASE_ID is not defined')
  }
  const data = await NotionHq.databases.query({
    database_id: NOTION_DATABASE_ID,
    page_size: 25,
    filter: {
      property: 'Listed',
      checkbox: {
        equals: true,
      },
    },
    sorts: [
      {
        property: 'Created',
        direction: 'descending',
      },
    ],
  })
  return {
    props: {
      data,
    },
  }
}

export default Home
